import { Link, mergeClasses } from '@expo/styleguide';
import { BranchIcon } from '@expo/styleguide-icons/custom/BranchIcon';
import { GithubIcon } from '@expo/styleguide-icons/custom/GithubIcon';
import { Tag01Icon } from '@expo/styleguide-icons/outline/Tag01Icon';
import { type ReactNode, type PropsWithChildren } from 'react';

import { isSHA1Hash } from '~/common/helpers';
import { AppDataFragment, BuildFragment, JobRunDataFragment } from '~/graphql/types.generated';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableEmptyCellText } from '~/ui/components/Table/TableEmptyCellText';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';
import { CALLOUT, FOOTNOTE } from '~/ui/components/text';

type GitRefCellProps = {
  gitCommitHash?: string | null;
  gitCommitMessage?: string | null;
  isGitWorkingTreeDirty?: boolean | null;
  gitRef?: string | null;
  app?: AppDataFragment | BuildFragment['app'] | JobRunDataFragment['app'];
  disableLinks?: boolean;
  className?: string;
};

function getGitRefWithIcon(gitRef?: string | null): { icon: ReactNode; label?: string } {
  if (!gitRef) {
    return {
      icon: null,
      label: undefined,
    };
  }

  if (gitRef.startsWith('refs/heads/')) {
    return {
      icon: <BranchIcon className="!icon-2xs relative top-px shrink-0 text-icon-tertiary" />,
      label: gitRef.replace('refs/heads/', ''),
    };
  } else if (gitRef.startsWith('refs/tags/')) {
    return {
      icon: <Tag01Icon className="!icon-2xs relative top-px shrink-0 text-icon-tertiary" />,
      label: gitRef.replace('refs/tags/', ''),
    };
  }

  return {
    icon: null,
    label: gitRef,
  };
}

export function GitRefCell({
  gitCommitHash,
  gitCommitMessage,
  isGitWorkingTreeDirty,
  gitRef,
  app,
  disableLinks,
  className,
}: GitRefCellProps) {
  if (!gitCommitHash && !gitCommitMessage && !gitRef) {
    return (
      <TableCell hideOnMobile>
        <TableEmptyCellText />
      </TableCell>
    );
  }

  const githubRepositoryUrl =
    app?.__typename === 'App' ? app.githubRepository?.githubRepositoryUrl : null;

  const showGitRef = gitRef && !isSHA1Hash(gitRef);

  const hash = (
    <CALLOUT
      tag="code"
      theme={showGitRef ? 'secondary' : 'default'}
      className="max-w-full truncate text-left">
      {gitCommitHash?.slice(0, 7)}
      {isGitWorkingTreeDirty ? '*' : ''}
    </CALLOUT>
  );

  const gitRefHref =
    !disableLinks && githubRepositoryUrl && gitRef ? `${githubRepositoryUrl}/tree/${gitRef}` : null;
  const commitHref =
    !disableLinks && githubRepositoryUrl && gitCommitHash
      ? `${githubRepositoryUrl}/commit/${gitCommitHash}`
      : null;

  const { icon, label } = getGitRefWithIcon(gitRef);

  const isOnlyOneLink = [gitRefHref, commitHref].length === 1;

  const git = (
    <>
      {gitRef && <GithubIcon className="shrink-0 text-icon-tertiary" />}
      <div className="flex min-w-0 flex-col items-start truncate text-left">
        {showGitRef ? (
          gitRefHref && !isOnlyOneLink ? (
            <div className="flex items-center gap-1">
              {icon}
              <Link
                href={gitRefHref}
                openInNewTab
                className="h-5 max-w-full truncate rounded-md px-1 leading-5 hocus:bg-element">
                <CALLOUT className="truncate">{label}</CALLOUT>
              </Link>
            </div>
          ) : (
            <div className="flex items-center gap-1">
              {icon}
              <CALLOUT className="max-w-full truncate">{label}</CALLOUT>
            </div>
          )
        ) : null}
        {commitHref && !isOnlyOneLink ? (
          <WithTooltip gitCommitMessage={gitCommitMessage}>
            <Link
              href={commitHref}
              openInNewTab
              className="flex h-5 max-w-full rounded-md px-1 leading-5 hocus:bg-element">
              {hash}
            </Link>
          </WithTooltip>
        ) : (
          hash
        )}
      </div>
    </>
  );

  const link = isOnlyOneLink ? (
    <Link
      openInNewTab
      className={mergeClasses(
        'flex h-full w-full items-center truncate',
        isOnlyOneLink ? 'gap-2' : 'gap-1'
      )}
      href={gitRefHref ?? commitHref ?? ''}>
      {git}
    </Link>
  ) : (
    git
  );

  const cell = (
    <TableCell
      hideOnMobile
      theme={isOnlyOneLink ? 'interactive' : 'default'}
      className={mergeClasses(
        'flex min-h-[44px] flex-1 items-center gap-2 self-stretch truncate',
        'max-md-gutters:hidden',
        className
      )}>
      {link}
    </TableCell>
  );

  if (isOnlyOneLink) {
    return <WithTooltip gitCommitMessage={gitCommitMessage}>{cell}</WithTooltip>;
  }

  return cell;
}

function WithTooltip({
  gitCommitMessage,
  children,
}: PropsWithChildren<{
  gitCommitMessage?: string | null;
}>) {
  if (!gitCommitMessage) {
    return <>{children}</>;
  }

  return (
    <TooltipRoot>
      <TooltipTrigger className="hidden max-w-full truncate md:flex md:h-full">
        {children}
      </TooltipTrigger>
      <TooltipContent align="start" sideOffset={4} className="max-w-[300px]">
        {gitCommitMessage
          .slice(0, 256)
          .split('\n')
          .map((line, index) => (
            <FOOTNOTE key={`line-${index}`} className="!break-words">
              {line}
            </FOOTNOTE>
          ))}
        {gitCommitMessage.length > 256 ? '…' : ''}
      </TooltipContent>
    </TooltipRoot>
  );
}

export function MobileGitRefCell({
  gitCommitHash,
  isGitWorkingTreeDirty,
  gitRef,
}: {
  gitCommitHash?: string | null;
  isGitWorkingTreeDirty?: boolean | null;
  gitRef?: string | null;
}) {
  const { label } = gitRef ? getGitRefWithIcon(gitRef) : { label: gitRef };

  if (gitRef && !isSHA1Hash(gitRef)) {
    return (
      <div className="flex items-center gap-1">
        <GithubIcon className="shrink-0 text-icon-tertiary" />
        <CALLOUT tag="span" className="truncate">
          {label}
        </CALLOUT>
      </div>
    );
  }

  if (gitCommitHash) {
    return (
      <CALLOUT tag="span" className="truncate">
        {gitCommitHash?.slice(0, 7)}
        {isGitWorkingTreeDirty ? '*' : ''}
      </CALLOUT>
    );
  }

  return null;
}
