import { Link, mergeClasses } from '@expo/styleguide';
import { AndroidIcon } from '@expo/styleguide-icons/custom/AndroidIcon';
import { AppleIcon } from '@expo/styleguide-icons/custom/AppleIcon';
import { BranchIcon } from '@expo/styleguide-icons/custom/BranchIcon';
import { AlignHorizontalCentre02Icon } from '@expo/styleguide-icons/outline/AlignHorizontalCentre02Icon';
import { DotsVerticalIcon } from '@expo/styleguide-icons/outline/DotsVerticalIcon';
import { Earth02Icon } from '@expo/styleguide-icons/outline/Earth02Icon';
import { QrCode01Icon } from '@expo/styleguide-icons/outline/QrCode01Icon';
import { RefreshCw02Icon } from '@expo/styleguide-icons/outline/RefreshCw02Icon';
import { Trash01Icon } from '@expo/styleguide-icons/outline/Trash01Icon';
import { format } from 'date-fns/format';
import { useEffect, useState } from 'react';

import { dateFnsFormats } from '~/common/constants';
import { useRuntimesConceptFeatureGate } from '~/common/gating/gates/RuntimesConceptFeatureGate';
import { getVariableTimeString } from '~/common/getVariableTimeString';
import { getProjectURL } from '~/common/helpers';
import { TableUpdateFragment } from '~/graphql/types.generated';
import {
  GitRefCell,
  MobileGitRefCell,
} from '~/scenes/Dashboard/Projects/BuildsListScene/GitRefCell';
import { useDeleteUpdate } from '~/scenes/UpdateListScene/useDeleteUpdate';
import { ActivityIndicator } from '~/ui/components/ActivityIndicator';
import { Button } from '~/ui/components/Button';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { Dropdown } from '~/ui/components/Dropdown';
import { DropdownItem } from '~/ui/components/Dropdown/DropdownItem';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableCellText } from '~/ui/components/Table/TableCellText';
import { TableInlineCell } from '~/ui/components/Table/TableInlineCell';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipText } from '~/ui/components/Tooltip/TooltipText';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';
import { FormStates } from '~/ui/components/form/FormStates';
import { CALLOUT } from '~/ui/components/text';

export type UpdateRowProps = {
  updateGroup: TableUpdateFragment[];
  accountName: string;
  disableLinks?: boolean;
  hideTimestamp?: boolean;
  nested?: boolean;
  formState?: FormStates;
  deleteUpdate?: ReturnType<typeof useDeleteUpdate>['deleteUpdate'];
  republishUpdate?: () => Promise<void>;
  previewUpdateGroup?: (updateGroupId: string) => void;
  isActivityView?: boolean;
};

export function UpdateRow({
  updateGroup,
  accountName,
  disableLinks,
  hideTimestamp,
  nested,
  formState,
  deleteUpdate,
  republishUpdate,
  previewUpdateGroup,
  isActivityView,
}: UpdateRowProps) {
  const update = updateGroup[0]; // an arbitrary update from the group
  const platforms = updateGroup.map((update) => update.updatePlatform);
  const [renderDate, setRenderDate] = useState(false);
  const runtimesConceptFeatureGate = useRuntimesConceptFeatureGate();

  useEffect(() => {
    setRenderDate(true);
  }, []);

  const primaryCell = (
    <TableCell
      isActivityView={isActivityView}
      mobilePrimaryCell
      theme={disableLinks ? 'default' : 'interactive'}
      className={mergeClasses(
        'w-full flex-col justify-center self-stretch truncate',
        nested && 'pl-6'
      )}>
      <TableCellText weight="medium" className="truncate">
        {update.message}
      </TableCellText>
      {!hideTimestamp && (
        <ContentLoading loading={!renderDate} height={24} width={150} className="flex">
          <TooltipRoot>
            <TooltipTrigger>
              <CALLOUT theme="secondary">{getVariableTimeString(update.createdAt)}</CALLOUT>
            </TooltipTrigger>
            <TooltipContent side="bottom" align="start">
              <TooltipText>
                {renderDate ? format(new Date(update.createdAt), dateFnsFormats.timestamp) : ''}
              </TooltipText>
            </TooltipContent>
          </TooltipRoot>
        </ContentLoading>
      )}
      <div className="hidden flex-wrap gap-2 max-md-gutters:mt-1.5 max-md-gutters:flex">
        {update.updateGitCommitHash && (
          <TableInlineCell>
            <CALLOUT theme="secondary">Git ref:</CALLOUT>
            <MobileGitRefCell
              gitCommitHash={update.updateGitCommitHash}
              isGitWorkingTreeDirty={update.updateIsGitWorkingTreeDirty}
            />
          </TableInlineCell>
        )}
        <TableInlineCell>
          <CALLOUT theme="secondary">Platforms: </CALLOUT>
          {getPlatformValue(platforms)}
        </TableInlineCell>
        <TableInlineCell>
          <CALLOUT>Runtime:</CALLOUT>
          <CALLOUT theme="default">{update.updateRuntime.version}</CALLOUT>
        </TableInlineCell>
      </div>
    </TableCell>
  );

  const branchCell = (
    <TableCell
      hideOnMobile
      theme={disableLinks ? 'default' : 'interactive'}
      className="flex w-full flex-col gap-1 max-md-gutters:hidden">
      <div className="flex min-h-[44px] w-full items-center gap-2 self-stretch">
        <BranchIcon className="shrink-0" />
        <TableCellText className="truncate">{update.branch.name}</TableCellText>
      </div>
    </TableCell>
  );

  const runtimeCell = (
    <TableCell
      hideOnMobile
      className="min-h-[44px] w-full items-center gap-2 self-stretch truncate"
      theme={disableLinks || !runtimesConceptFeatureGate.isEnabled() ? 'default' : 'interactive'}>
      <AlignHorizontalCentre02Icon className="shrink-0" />
      <TableCellText className="truncate">
        {update.updateRuntime.version.length > 12
          ? `${update.updateRuntime.version.slice(0, 12)}…`
          : update.updateRuntime.version}
      </TableCellText>
    </TableCell>
  );

  return (
    <>
      <TableSeparator />
      <TableRow>
        {disableLinks ? (
          primaryCell
        ) : (
          <Link
            href={getProjectURL(accountName, update.app.slug, `updates/${update.group}`)}
            className="flex size-full items-center">
            {primaryCell}
          </Link>
        )}
        <GitRefCell
          app={update.app}
          gitCommitHash={update.updateGitCommitHash}
          isGitWorkingTreeDirty={update.updateIsGitWorkingTreeDirty}
          disableLinks={disableLinks}
        />
        <TableCell hideOnMobile>
          <div className="flex items-center gap-2">{getPlatformValue(platforms)}</div>
        </TableCell>
        {disableLinks || !runtimesConceptFeatureGate.isEnabled() ? (
          runtimeCell
        ) : (
          <Link
            href={getProjectURL(
              accountName,
              update.app.slug,
              `runtimes/${encodeURIComponent(update.updateRuntime.id)}`
            )}
            className="flex h-full items-center">
            {runtimeCell}
          </Link>
        )}
        {disableLinks ? (
          branchCell
        ) : (
          <Link
            href={getProjectURL(
              accountName,
              update.app.slug,
              `branches/${encodeURIComponent(update.branch.name)}`
            )}
            className="flex h-full items-center max-md-gutters:hidden">
            {branchCell}
          </Link>
        )}
        {previewUpdateGroup && (
          <TableCell className={mergeClasses(!deleteUpdate && nested && 'pr-6')}>
            <Button
              theme="secondary"
              size="sm"
              testID="artifact-options-button"
              className="shrink-0"
              leftSlot={<QrCode01Icon className="icon-sm" />}
              onClick={() => {
                previewUpdateGroup(update.group);
              }}>
              Preview
            </Button>
          </TableCell>
        )}
        {deleteUpdate && (
          <TableCell className={mergeClasses(nested && 'pr-6')} hideOnMobile>
            {formState === FormStates.LOADING ? (
              <Button disabled theme="quaternary" leftSlot={<ActivityIndicator />} />
            ) : (
              <Dropdown
                className="min-w-[180px]"
                trigger={
                  <Button
                    status={formState}
                    theme="quaternary"
                    leftSlot={<DotsVerticalIcon className="icon-md" />}
                  />
                }>
                <DropdownItem label="Republish" Icon={RefreshCw02Icon} onSelect={republishUpdate} />
                <DropdownItem
                  destructive
                  label="Delete"
                  Icon={Trash01Icon}
                  onSelect={deleteUpdate}
                />
              </Dropdown>
            )}
          </TableCell>
        )}
      </TableRow>
    </>
  );
}

export function getPlatformValue(platform: string[], className?: string) {
  return platform.sort().map((platform) => {
    return (
      <TooltipRoot key={platform}>
        <TooltipTrigger>
          {platform === 'android' && <AndroidIcon key={platform} className={className} />}
          {platform === 'ios' && <AppleIcon key={platform} className={className} />}
          {platform === 'web' && <Earth02Icon key={platform} className={className} />}
        </TooltipTrigger>
        <TooltipContent sideOffset={8}>
          <TooltipText>
            {platform === 'android' && 'Android'}
            {platform === 'ios' && 'iOS'}
            {platform === 'web' && 'Web'}
          </TooltipText>
        </TooltipContent>
      </TooltipRoot>
    );
  });
}
